import React from 'react'
import { Stack, Main } from '@layout'
import { Text } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Section from '@components/Section'

export default () => (
  <>
    <Seo title='Privacy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Chính sách bảo mật thông tin cá nhân"
          subheader='Có hiệu lực từ 1/6/2020'
        />
        <Divider />
        <Section>
          <Text variant='p'>
          Quyền riêng tư của bạn rất quan trọng với chúng tôi. Dưới đây là chính sách bảo mật nhằm tôn trọng quyền riêng tư của bạn về bất kỳ thông tin nào bạn đăng ký trên trang web của chúng tôi, https://genkihub.com và các trang web khác mà chúng tôi sở hữu và vận hành.
          </Text>
        </Section>
        <Section>
          <Text variant='h2'>
            THÔNG TIN CHÚNG TÔI THU THẬP
          </Text>
          <Text variant='h3'>
            Dữ liệu đăng nhập
          </Text>
          <Text variant='p'>
            Khi bạn truy cập trang web của chúng tôi, máy chủ của chúng tôi có thể tự động lưu dữ liệu do trình duyệt web của bạn cung cấp, Nó có thể bao gồm địa chỉ Giao thức Internet (IP) trên máy tính của bạn, loại và phiên bản trình duyệt của bạn, các trang bạn truy cập, thời gian và ngày bạn truy cập, thời gian dành cho mỗi trang và các chi tiết khác.
            </Text>
          <Text variant='p'>
            Chúng tôi cũng có thể thu thập dữ liệu về thiết bị bạn đang sử dụng để truy cập trang web của chúng tôi. Dữ liệu này có thể bao gồm loại thiết bị, hệ điều hành, số nhận dạng thiết bị duy nhất, cài đặt thiết bị và dữ liệu vị trí địa lý. Những gì chúng tôi thu thập có thể phụ thuộc vào cài đặt riêng của thiết bị và phần mềm của bạn. Chúng tôi khuyên bạn nên kiểm tra các chính sách của nhà sản xuất thiết bị hoặc nhà cung cấp phần mềm của bạn để tìm hiểu thông tin nào họ cung cấp cho chúng tôi.
          </Text>
          <Text variant='h3'>
            Thông tin cá nhân
          </Text>
          <Text variant='p'>
            Chúng tôi có thể yêu cầu thông tin cá nhân, chẳng hạn như:
            <ul>
              <li>Tên</li>
              <li>E-mail</li>
            </ul>
            Dữ liệu này được coi là thông tin nhận dạng, vì nó có thể nhận dạng cá nhân bạn. Chúng tôi chỉ yêu cầu thông tin cá nhân liên quan đến việc cung cấp cho bạn thông tin, dịch vụ và chỉ sử dụng thông tin đó để giúp chúng tôi cải thiện dịch vụ này.
          </Text>
          <Text variant='h2'>
            CÁCH CHÚNG TÔI THU THẬP THÔNG TIN
          </Text>
          <Text variant='p'>
            Chúng tôi thu thập thông tin bằng các phương tiện hợp pháp, với kiến ​​thức và sự đồng ý của bạn. Chúng tôi cũng cho bạn biết lý do tại sao chúng tôi thu thập nó và làm thế nào nó sẽ được sử dụng, tuy nhiên bạn có quyền từ chối yêu cầu của chúng tôi về thông tin này.
          </Text>
          <Text variant='h2'>
            SỬ DỤNG THÔNG TIN
          </Text>
          <Text variant='p'>
            Chúng tôi có thể sử dụng kết hợp thông tin nhận dạng và không xác định để hiểu khách truy cập của chúng tôi là ai, cách họ sử dụng dịch vụ của chúng tôi và cách chúng tôi có thể cải thiện trải nghiệm của họ về trang web của chúng tôi trong tương lai. Chúng tôi không tiết lộ chi tiết cụ thể về thông tin này, nhưng có thể chia sẻ các phiên bản tổng hợp và ẩn danh của thông tin này, ví dụ, trong các báo cáo xu hướng sử dụng của trang web và khách hàng.
          </Text>
          <Text variant='p'>
            Chúng tôi có thể sử dụng thông tin cá nhân của bạn để liên hệ với bạn với các cập nhật về trang web và dịch vụ của chúng tôi, cùng với nội dung quảng cáo mà chúng tôi tin rằng có thể bạn quan tâm. Nếu bạn muốn từ chối nhận nội dung quảng cáo, bạn có thể làm theo hướng dẫn hủy đăng ký trên mạng được cung cấp cùng với bất kỳ bức thư quảng cáo nào từ chúng tôi.
          </Text>
          <Text variant='h2'>
            XỬ LÝ VÀ LƯU TRỮ DỮ LIỆU
          </Text>
          <Text variant='p'>
            Chúng tôi chỉ lưu giữ thông tin cá nhân miễn là cần thiết để cung cấp dịch vụ hoặc cải thiện dịch vụ của chúng tôi trong tương lai. Mặc dù chúng tôi giữ lại dữ liệu này, chúng tôi sẽ bảo vệ nó trong các phương tiện thương mại có thể chấp nhận được để ngăn ngừa mất mát và trộm cắp, cũng như truy cập trái phép, tiết lộ, sao chép, sử dụng hoặc sửa đổi. Điều đó có nghĩa là không có phương thức truyền hoặc lưu trữ điện tử nào an toàn 100% và không thể đảm bảo an toàn dữ liệu tuyệt đối.
          </Text>
          <Text variant='p'>
            Nếu bạn yêu cầu thông tin cá nhân của bạn bị xóa hoặc khi thông tin cá nhân của bạn không còn phù hợp với hoạt động của chúng tôi, chúng tôi sẽ xóa thông tin đó khỏi hệ thống của chúng tôi trong một khung thời gian hợp lý.
          </Text>
          <Text variant='h3'>
            Cookie
          </Text>
          <Text variant='p'>
            Chúng tôi sử dụng cookie để thu thập thông tin về bạn và hoạt động của bạn trên trang web của chúng tôi. Cookie là một phần dữ liệu nhỏ mà trang web của chúng tôi lưu trữ trên máy tính của bạn và truy cập mỗi khi bạn truy cập, vì vậy chúng tôi có thể hiểu cách bạn sử dụng trang web của chúng tôi. Điều này giúp chúng tôi phục vụ bạn nội dung dựa trên các tùy chọn bạn đã chỉ định. Vui lòng tham khảo Chính sách cookie của chúng tôi để biết thêm thông tin.
          </Text>
          <Text variant='h2'>
            QUYỀN TRUY CẬP THÔNG TIN CỦA BÊN THỨ BA
          </Text>
          <Text variant='p'>
            Chúng tôi sử dụng dịch vụ của bên thứ ba cho việc:
            <ul>
              <li>Phân tích số liệu</li>
              <li>Quảng cáo</li>
              <li>Phân phối nội dung</li>
              <li>Email marketing</li>
              <li>Xử lý thanh toán</li>
            </ul>
          </Text>
          <Text variant='p'>
            Chúng tôi không chia sẻ bất kỳ thông tin nhận dạng cá nhân nào với họ mà không có sự đồng ý rõ ràng của bạn. Chúng tôi không cho phép họ tiết lộ hoặc sử dụng bất kỳ dữ liệu nào của chúng tôi cho bất kỳ mục đích nào khác.
          </Text>
          <Text variant='p'>
            Chúng tôi không chia sẻ hoặc cung cấp thông tin cá nhân cho bên thứ ba. Chúng tôi không bán hoặc cho thuê thông tin cá nhân của bạn cho các nhà tiếp thị hoặc bên thứ ba.
          </Text>
          <Text variant='h2'>
            QUYỀN RIÊNG TƯ CỦA TRẺ EM
          </Text>
          <Text variant='p'>
            Chúng tôi cam kết bảo vệ sự riêng tư của trẻ em sử dụng dịch vụ của chúng tôi. Chúng tôi khuyến khích các bậc cha mẹ tham gia với chúng tôi trong việc bảo vệ quyền riêng tư của con cái họ, đảm bảo trải nghiệm trực tuyến an toàn và thú vị. Vui lòng tham khảo Chính sách bảo mật của trẻ em của chúng tôi để biết thêm thông tin.
          </Text>
          <Text variant='h2'>
            GIỚI HẠN CỦA CHÍNH SÁCH CỦA CHÚNG TÔI
          </Text>
          <Text variant='p'>
            Chính sách bảo mật này chỉ bao gồm việc thu thập và xử lý dữ liệu của GenkiHub và các site chúng tôi quản lý. Trang web của chúng tôi có thể liên kết đến các trang web bên ngoài không được điều hành bởi chúng tôi. Xin lưu ý rằng chúng tôi không kiểm soát nội dung và chính sách của các trang web đó và không thể chấp nhận trách nhiệm hoặc trách nhiệm pháp lý đối với các hoạt động bảo mật tương ứng của họ.
          </Text>
          <Text variant='h2'>
            QUYỀN VÀ TRÁCH NHIỆM CỦA BẠN
          </Text>
          <Text variant='p'>
            Là người dùng của chúng tôi, bạn có quyền được thông báo về cách dữ liệu của bạn được thu thập và sử dụng. Bạn có quyền biết những dữ liệu nào chúng tôi thu thập về bạn và cách xử lý dữ liệu đó. Bạn có quyền sửa và cập nhật bất kỳ thông tin cá nhân nào về bạn và yêu cầu xóa thông tin này.
            Bạn có quyền hạn chế hoặc phản đối việc chúng tôi sử dụng dữ liệu của bạn, trong khi vẫn có quyền sử dụng thông tin cá nhân của bạn cho mục đích riêng của bạn. Bạn có quyền từ chối dữ liệu về việc bạn đang được sử dụng trong các quyết định chỉ dựa trên xử lý tự động.
            Vui lòng liên hệ với chúng tôi nếu bạn có bất kỳ mối quan tâm hoặc câu hỏi nào về cách chúng tôi xử lý dữ liệu và thông tin cá nhân của bạn.
            Contact@genkihub.com
          </Text>
        </Section>
      </Main>
    </Stack>
  </>
)
